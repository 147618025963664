import Vue from 'vue';
import App from './App.vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/global.css';
import axios from "axios";
import VueRouter from "vue-router";
import router from './router';

Vue.prototype.$axios = axios;
Vue.prototype.$httpUrl = 'http://120.27.236.235:9080';//120.27.236.235
Vue.config.productionTip = false;
document.title = '杭州漉沙科技有限公司'
document.Description = '杭州漉沙科技有限公司，主要致力于AGV行业的服务商，主要业务为提供物流机器人、无线网络的整体解决方案、项目落地实施、项目的售后维护、机器视觉、服务器硬件提供等。'

// // 获取 cookie 函数
// function getCookie(name) {
//   let match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
//   if (match) return match[2];
//   return null;
// }
//
// // 配置 axios 拦截器
// axios.interceptors.request.use(config => {
//   const token = getCookie('access_token');
//   if (token) {
//     config.headers['Authorization'] = `${token}`;
//   }
//   return config;
// }, error => {
//   return Promise.reject(error);
// });
//
// axios.interceptors.response.use(response => {
//   if (response.data.code == 401) {
//     Vue.prototype.$message({
//       message: response.data.message,
//       type: 'error'
//     });
//     document.cookie = 'access_token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/'; // 清除 token
//     if (router.currentRoute.path !== '/') {
//       router.push('/');
//     }
//   }
//   return response;
// });

axios.defaults.withCredentials = true;//zhengque

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(ElementUI, { size: "small" });

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');

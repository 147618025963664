<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style>
#app {
  height: 100%;
}

</style>
<script >

export default {
  name:'APP',
  components:{
  }
}
</script>